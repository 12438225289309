html{
  font-size: 62.5%;
  box-sizing: border-box;
}
body{
  font: 3rem Candara #032F48;
  height: 100vh;
  margin: 0;
}
button {
  font-size: 1.6rem;
  color:  #032F48;
}

.full-width {
  width: 100%;
}


#root {
  height: 100%;
}

.grid-container{
  display: grid;
  grid-template-areas:
  "header"
  "main"
  "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 10rem 1fr 5rem;
  height: 100%;
}
.header{
  font-size: 1.6rem;
  grid-area: header;
  background-color: #032F48;
  color: #FBBE55;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;


}
.brand a{
  font: 1.6rem Brush Script MT;
  color: #FBBE55;
  font-size: 4rem;
  font-weight: bold;
  text-decoration: none;

}
.title-image{
  position: relative;
  /* height: 10rem; */
  
  max-height: 10rem;
  top: 0%;
  max-width: 100%;
  min-width: 10rem;
}


.header-links a{
  padding: 1rem;
  color: #FBBE55;
}
.header-links a:hover{
  color:#F37E30;
}
.main{
  grid-area: main;
  background-color: #E9E1B8;
  z-index: 9999;
}
.footer{
  grid-area: footer;
  background-color: #032F48;
  color: #FBBE55;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
a{
  text-decoration: none;
  color: #032F48;

}
a:hover {
  color:#F37E30;

}
.products {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.products li {
  list-style-type: none;
  padding:0;
  flex: 0 1 34rem;
  margin: 1rem;
  height: 43rem;
  border-bottom: 0.5rem #032F48 solid;
}
.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.product-name {
  font-size: 2rem;
  color: #032F48;
  font-weight: bold;
  
  
}
.product-brand {
  font-size: 1.2rem;
  color: #032F48;
  font-weight: bold;
}

.product-price {
  font-size: 2.5rem;
  color: #032F48;
  font-weight: bold;
}
.product-image{
  max-width: 34rem;
  max-height: 34rem;
}

.back-to-result{
  font-size: 1.6rem;
  color: #032F48;
  font-weight: bold;
}

.brand button{
  font-size: 3rem;
  padding-bottom: 10%;
  background:none;
  border:none;
  color:#FBBE55;
  cursor: pointer;

}
/* .for-side-bar{
  position: relative;
  height: 100%;
} */
.sidebar {
  position: fixed;
  transition: all .5s;
  transform: translateX(-31rem);
  width: 30rem;
  background-color: #FBBE55;
  border: solid 0.5rem #032F48;
  height: 100%;
  font-size: 2rem;
  color: #032F48;
  overflow: scroll;
  flex-direction: column;
  
}



.sidebar.open{
  flex-direction: column;
  
  transform: translateX(0);
  z-index: 99999 !important;
}
.sidebar-close-button{
  
  border-radius: 50%;
  border: .1rem #032F48 solid;
  background-color: #E9E1B8;
  width: 3rem;
  height: 3rem;
  padding: .5rem;
  font-size: 2rem;
  padding-top: 0;
  cursor: pointer;
  position: absolute;
  right: .5rem;
  top: 1.5rem
}
.sidebar-image{
    position: absolute;
    width: 30rem;
    bottom: 0rem;
}

.sidebar-image-icon-top{
  position: absolute;
  height: 20%;
  max-height: 20rem;
  top: 40%;
  left: 5rem;
  margin-top: 5rem;
  top: 1%;
  left: 20%;
  
}
.sidebar-text-top {
  position: absolute;
  font-size: 2rem;
  color: #032F48;
  left: 2rem;
  right: 2rem;
  list-style-type: none;
  text-align: center;
  text-decoration: underline;
  top: 25%;
}
.sidebar-image-icon-middle{
  position: absolute;
  height: 20%;
  top: 30%;
  left: 20%;
  
}
.sidebar-text-middle {
  position: absolute;
  font-size: 2rem;
  color: #032F48;
  left: 2rem;
  right: 2rem;
  list-style-type: none;
  text-align: center;
  text-decoration: underline;
  top: 52%;
}
.sidebar-image-icon-bottom{
  position: absolute;
  height: 20%;
  top: 60%;
  left: 20%;
}

.sidebar-text-bottom {
  position: absolute;
  font-size: 2rem;
  color: #032F48;
  left: 2rem;
  right: 2rem;
  list-style-type: none;
  text-align: center;
  text-decoration: underline;
  top: 82%;
}

/* Product Details */
.details {
  font-size: 1.8rem;
  color: #032F48;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 2rem;
}
.details-image {
  flex: 2 1 40rem;
}

.details-image img {
  max-width: 40rem;
  width: 100%;
}
.details-info {
  flex: 1 1 30rem;
  padding-right: 15rem;
}
.details-action {
  flex: 1 1 30rem;
}

.details-info ul,
.details-action ul {
  list-style-type: none;
  padding: 0;
}
.details-info li,
.details-action li {
  margin-bottom: 1rem;
}

.back-to-shop {
  font-size: 1.6rem;
  padding: 2rem;
}

.details-action {
  border: 0.8rem #032F48 solid;
  border-radius: 0.5rem;
  background-color: #E9E1B8;
  padding: 1rem;
}

.details-action ul li:last-child {
  display: flex;
  flex-direction: column;
}
.button {
  font: 4rem #032F48;
  padding: 1rem;
  background-color: #FBBE55;
  border: 0.2rem #E9E1B8 solid;
  border-radius: 0.5rem;
  cursor: pointer;
}
.button:hover {
  border: 0.2rem #032F48 solid;
  font: 1.6rem Candara #F37E30;
}

.text-center {
  text-align: center;
}

/* Cart */

.cart {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  align-items: flex-start;
  font: 4rem #032F48;
}
.cart-list {
  flex: 3 1 60rem;
  
}
.cart-action {
  flex: 1 1 20rem;
  background-color: #E9E1B8;
  border: 0.2rem #032F48 solid;
  border-radius: 0.5rem;
  padding: 1rem;
  height: 18rem;
  margin-top: 12rem;
  font-size: 1.5rem;
  color: #032F48;
}
.cart-list-container {
  width:90%;
  list-style-type: none;
  padding: 1rem;
  align-items: center;
  
}
.cart-list-container li {
  display: flex;
  justify-content: space-between;
  font: 4rem #032F48;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem #032F48 solid;
  align-items: center;
  
}
.cart-list-container li img {
  max-width: 10rem;
  max-height: 10rem;
}
.cart-list-container li:first-child {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  
}
.cart-image {
  flex: 1 1;
}
.cart-name {
  flex: 8 1;
  font-size: 2rem;
}
.cart-price {
  flex: 1 1;
  font-size: 2.5rem;
  text-align: right;
}

/* forms */

.form {
  grid-area: main;
  position: relative;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.form-container {
  display: flex;
  flex-direction: column;
  max-width: 42rem;
  min-width: 35rem;
  padding: 2rem;
  border: 0.1rem #032F48 solid;
  border-radius: 0.5rem;
  list-style-type: none;
  color: #032F48;
}
.form-container li {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
}
input {
  padding: 1rem;
  border: 0.1rem #032F48 solid;
  border-radius: 0.5rem;
}

/* admin */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  padding: 1rem;
  list-style-type: none;
  z-index: 1;
  background-color: #203040;
  margin: 0;
  margin-top: 0.4rem;
}
.dropdown:hover .dropdown-content {
  
  display: table;
}

/* Products */

.product-header{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

}

.content-margined{
  margin: 1rem;
}

.table{
  width: 100%;
}
th{
  text-align: left;
}
tbody>tr:nth-child(odd){
  background-color: #f1edd3;
}

/* About Page */

.about {
  grid-area: main;
  position: relative;
  max-width: 100%;
  background-color: #032F48;
  color: #FBBE55;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  height: auto;
  text-align: center;
  font-size: 2rem;
}


.about-image img {
  grid-area: main;
  position: relative;
  width: 100%;
  z-index: 1 !important;
}
.about-info {

  
  position: relative;
  
  width: 100%;
}



/* Checkout Steps */

.checkout-steps{
  display: flex;
  justify-content: space-between;
  width: 40rem;
  margin: 1rem auto;
 
}
.checkout-steps > div{
  border-top: .3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1 1;
  padding-top: 1rem;
}
.checkout-steps > div.active{
  border-top: .3rem #F37E30 solid;
  color: #F37E30;
}
.sign-in-title{
  text-align: center;
  font-size: 3rem;
  color: #032F48

}
.form-subtext{
  text-align: center;
  color: #032F48;
}

.form-subtext-sec{
  color: #032F48;
}

/* Place Order */
.orderpage{
  grid-area: main;
  position: relative;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.placeorder{
  height: 100%;
  display: flex;
  flex-grow: wrap;
  padding: 1rem;
  justify-content: space-between;
  text-decoration: none;
}

.placeorder-action{

  flex: 1 1 20rem;
  background-color: #E9E1B8;
  border: 0.2rem #032F48 solid;
  border-radius: 0.5rem;
  padding: 1rem;
  height: 20rem;
  margin-top: 5rem;
  font-size: 1.5rem;
  color: #032F48;
  text-decoration: none;
  
}

.placeorder-info > div{
  border: .3rem #032F48 solid;
  border-radius: 1rem;
  background-color: #f3f0df;
  padding: 1rem;
  margin: 1rem;
  font-size: 1.5rem;
  color: #032F48;
  grid-area: main;
  text-decoration: none;
  
}
.placeorder-info > div:first-child{
  margin-top: 0;
  font-size: 1.5rem;
  color: #032F48;
  text-decoration: none;
}
.placeorder-action > ul {
  padding: 0;
  list-style-type: none;
  font-size: 1.5rem;
  color: #032F48;
}
.placeorder-action > ul > li{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #032F48;
}
.placeorder-action > ul > li:last-child{
    font-size: 2rem;
    font-weight: bold;
    color: #D42D2f;
}
/* order */
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
img {
  border-radius: 0.5rem;
}
img.small {
  max-width: 5rem;
  width: 100%;
}
img.medium {
  max-width: 29rem;
  width: 100%;
}
.order-item-info {
  margin-left: 1rem;
}
.order-heading{
  font-size: 2rem;
  color: #032F48;
  text-decoration: none;
  margin-left: 1rem;
  text-align : center;
  
  flex-grow: wrap;
  padding: 1rem;
  
}
.ordersummary{
  margin-left: 1rem;
}

.events {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.paymentpage{
  grid-area: main;
  position: relative;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-decoration: none;
  color: #032F48;
}

.payment-action{
  margin-top: 10rem;
  flex: 1 1 ;
  border: 0.3rem #032F48 solid;
  border-radius: 1rem;
  background-color: #f3f0df;
  padding: 1rem;
  font-size: 1.5rem;
  color: #032F48;
  text-decoration: none;
  min-width: 30rem;
}

.placepayment{
  color: #032F48;
  display: flex;
  flex-grow: wrap;
  padding: 1rem;
  justify-content: space-between;
  text-decoration: none;
}


.payment-action > ul {
  padding: 0;
  list-style-type: none;
  font-size: 1.5rem;
  color: #032F48;
  text-decoration: none;
  
}

.paypal{
  margin-top: 3rem;
}

.order-list{
  align-items: center;
  position: relative;
  
}
.placeorder-info{
  
  display: flex;
  
  position: relative;
  
}

.order-info-box{
  color: #032F48;
  
  display: flex;
  position: relative;}
  
.order-container{
  text-decoration: none;
  list-style-type: none;
}
.allignleft{
  position: absolute;
  left: 1%;
}
.collectiontext{
  font-size: 1.5rem;
  color: #032F48;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  
}

.msgbox{
  width: 95%;
  height:40px;
  padding: 1rem
}